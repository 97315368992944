import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Excerpt from "../components/excerpt"

const Category = ({ pageContext }) => {
  const { name, posts } = pageContext

  return (
    <Layout>
      <SEO title={name} />
      <div className="index-title">
        { name.toUpperCase() }
      </div>
      {
        posts.map(post => (
          <Excerpt post={post} />
        ))
      }
    </Layout>
  )
}

export default Category